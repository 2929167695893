import { addToast, Button } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { createEvaluationOfGradeEntry } from '../requests';
import { useCreateEvaluationFormContext } from './EvaluationForm/context/CreateEvaluationFormContext';
import EndCourseGradeTable from './EvaluationForm/parts/Table/EndCourseGradeTable';
import EvaluationsTable from './EvaluationForm/parts/Table/EvaluationsTable';

interface CreateEvaluationGradeEntryFormProps {
  sectionId: number | string;
  refresh: () => void;
}

export default function CreateEvaluationGradeEntryForm({
  sectionId,
  refresh,
}: CreateEvaluationGradeEntryFormProps) {
  const { t } = useTranslation();
  const { gradeEntry, handleValidate } = useCreateEvaluationFormContext();

  const [btnLoading, setBtnLoading] = useState<boolean>(false);

  const onSubmitByCreateEvaluationGradeEntry = useCallback(async () => {
    setBtnLoading(true);
    let { error } = await createEvaluationOfGradeEntry(sectionId, gradeEntry);
    if (error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: t('gradeEntry.createEvaluation.msgErrorTables.errorSaveOfData'),
        style: { width: '600px' },
      });
    } else {
      addToast({
        icon: 'check',
        color: 'success',
        text: t('gradeEntry.createEvaluation.msgSuccess'),
        style: { width: '400px' },
      });
      refresh();
    }
    setBtnLoading(false);
  }, [sectionId, gradeEntry, t, refresh]);

  return (
    <>
      <Row className="mt-5 justify-content-between">
        <Col lg={3}>
          <EndCourseGradeTable />
        </Col>
        <Col lg={9} xl={8}>
          <EvaluationsTable />
        </Col>
      </Row>
      <Row>
        <Col md={9} />
        <Col>
          <Button
            type="button"
            text={t('gradeEntry.createEvaluation.save')}
            fullwidth
            size="lg"
            loading={btnLoading}
            onClick={handleValidate(onSubmitByCreateEvaluationGradeEntry)}
          />
        </Col>
      </Row>
    </>
  );
}
