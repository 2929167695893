import { Button, Icon, IconProps, Modal } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import CardTitle from '../Text/CardTitle';

interface ModalConfirmationProps {
  open: boolean;
  title: string;
  subtitle?: string;
  actionText?: string;
  onClose: () => void;
  onConfirm: () => void;
  columns?: {
    title: string;
    li: JSX.Element[] | JSX.Element;
  };
  icon?: IconProps['name'];
}

export function ModalConfirmation(props: ModalConfirmationProps) {
  const { t } = useTranslation();
  const {
    open,
    title,
    subtitle,
    actionText,
    onClose,
    onConfirm,
    columns,
    icon = 'warning',
  } = props;
  function toggle(): void {
    onClose();
  }
  function close(): void {
    onClose();
  }
  function confirm() {
    onClose();
    onConfirm();
  }
  return (
    <Modal isOpen={open} size="md" toggle={toggle}>
      <Row>
        <Col>
          <div className="text-center">
            <Icon color="secondary" name={icon} size="70px" />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="text-center">
            <CardTitle children={title} />
          </div>
        </Col>
      </Row>
      {subtitle && (
        <Row>
          <Col md={12}>
            <div className="text-center">
              <p className="fs-16 text-light">{subtitle}</p>
            </div>
          </Col>
        </Row>
      )}
      {columns && (
        <Row>
          <Col>
            <span>{columns.title}</span>
            <div className="student-content">
              <ul className="mb-5 mt-2">{columns.li}</ul>
            </div>
          </Col>
        </Row>
      )}
      <Row className="pt-5">
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 6 }}
          className="ml-auto"
        >
          <div>
            <Button
              type="button"
              text={t(`common.btnCancel`)}
              onClick={close}
              outlined
              fullwidth
            />
          </div>
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <div className="pb-3 pb-md-0">
            <Button
              type="button"
              text={actionText ? actionText : t('common.btnConfirm')}
              onClick={confirm}
              fullwidth
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
}
