import {
  CellFormatOptions,
  ColumnTable,
  DateInput,
  Icon,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../components/tooltip/Tooltip';
import { useTexts } from '../../../hooks/useTextsContext';

import {
  PartialEvaluation,
  PartialEvaluationSubevaluations,
} from './../../../types';
import { isBeforeLimitDate } from './../utils/validEvaluation';
import useStructureTypeIsFixed from './useStructureTypeIsFixed';

export interface PartialEvaluationGradeColumnsProps {
  onChange: (state: PartialEvaluationSubevaluations) => void;
  error?: boolean;
  partialEvaluation: PartialEvaluationSubevaluations;
  partialEvaluationIndex: number;
  deletePartialEvaluationByIndex: (
    row: { canItBeRemoved: boolean },
    data?: PartialEvaluation[],
  ) => any;
}

export default function useSubevaluationGradeColumns({
  onChange,
  error,
  partialEvaluation,
  partialEvaluationIndex,
  deletePartialEvaluationByIndex,
}: PartialEvaluationGradeColumnsProps) {
  const { t } = useTranslation();
  const texts = useTexts();
  const { isFixedType } = useStructureTypeIsFixed();

  const isExisingtNameDuplicate = useCallback(
    (index: number, name: string) => {
      let errorText = partialEvaluation.grades?.find((grade, key) => {
        if (key !== index) {
          if (!grade.name) {
            return false;
          }
          return grade.name === name;
        }
        return false;
      });
      if (errorText) {
        return `*${t(
          'gradeEntry.partialEvaluationGradeEntry.duplicationName',
        )}`;
      }
      return undefined;
    },
    [partialEvaluation.grades, t],
  );

  const onNameChange = useCallback(
    (row: { name?: string }) => {
      return (e: any) => {
        row.name = e.target.value;
        onChange(partialEvaluation);
      };
    },
    [partialEvaluation, onChange],
  );

  const onDateChange = useCallback(
    (row: { date?: string | Date | null }) => {
      return (value: any) => {
        if (value instanceof Date) {
          row.date = dayjs(value).format('YYYY-MM-DD');
        }
        row.date = value;
        onChange(partialEvaluation);
      };
    },
    [partialEvaluation, onChange],
  );

  const onWeightingChange = useCallback(
    (row: { percentage?: string }) => {
      return (e: any) => {
        let value = e.target.value;
        if (
          Number(value) <= 100 &&
          Number(value) >= 0 &&
          value.search(/\D/) === -1
        ) {
          row.percentage = value;
          onChange(partialEvaluation);
        }
      };
    },
    [partialEvaluation, onChange],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'evaluation',
        headerText: texts.table.subEvaluationsTable.columns.name,
        width: '50%',
        thClassName: 'text-center',
        cellFormat: ({ row, index }: CellFormatOptions<PartialEvaluation>) => {
          let errorText = error && !row.name ? texts.errors.require : undefined;
          let limitOfChar =
            row.name && row.name.length > 30
              ? texts.errors.charLimit
              : undefined;
          return (
            <TextInput
              key={index}
              name={`evaluation-${partialEvaluationIndex}-subevaluation-name-${
                index as number
              }`}
              label={texts.form.fields.nameSubEvaluation}
              type="text"
              value={row.name}
              errorText={
                isExisingtNameDuplicate(index as number, row.name as string) ||
                limitOfChar ||
                errorText
              }
              onChange={onNameChange(row)}
            />
          );
        },
      },
      {
        columnName: 'date',
        headerText: texts.table.subEvaluationsTable.columns.date,
        width: '40%',
        thClassName: 'text-center',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          // Validaciones
          const parentEvaluation = partialEvaluation;
          let errorText =
            error && !options.row.date ? texts.errors.require : undefined;
          const errorLimitDate =
            error &&
            parentEvaluation?.hasLimitDate &&
            !isBeforeLimitDate(parentEvaluation, options.row)
              ? texts.errors.notValidDate
              : undefined;

          return (
            <DateInput
              name={`evaluation-${partialEvaluationIndex}-subevaluation-date-${
                options.index as number
              }`}
              label={texts.form.fields.surrencerDate}
              value={options.row.date}
              onChange={onDateChange(options.row)}
              shouldUnregister={true}
              errorText={errorText ?? errorLimitDate}
            />
          );
        },
      },
      {
        columnName: 'percentage',
        headerText: texts.table.subEvaluationsTable.columns.percentage,
        width: '10%',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          // Validaciones
          const errorText =
            error && !options.row.percentage ? texts.errors.require : undefined;
          const value =
            options.row.percentage === '0' ? '' : options.row.percentage;

          return (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: '8px' }}
            >
              <div style={{ maxWidth: '100px' }}>
                <TextOutlinedInput
                  type="text"
                  name={`evaluation-${partialEvaluationIndex}-subevaluation-percentage-${
                    options.index as number
                  }`}
                  placeholder="—%"
                  value={value}
                  onChange={onWeightingChange(options.row)}
                  errorText={errorText}
                  readOnly={isFixedType}
                />
              </div>
              {!isFixedType && (
                <Tooltip
                  id={`info_tooltip_trash_${partialEvaluationIndex}_sub_${options.index}`}
                  placement="bottom"
                  text={texts.btn.deleteEvaluation}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={deletePartialEvaluationByIndex(
                      options.row,
                      options.data,
                    )}
                  >
                    <Icon name="trash" className="mb-3" />
                  </div>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];
  }, [
    texts,
    partialEvaluation,
    partialEvaluationIndex,
    onNameChange,
    onDateChange,
    onWeightingChange,
    isExisingtNameDuplicate,
    deletePartialEvaluationByIndex,
    error,
    isFixedType,
  ]);

  return columns;
}
