import { Button, ColumnTable, IconNameType, Table } from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';

import {
  TeacherSectionListOfGradeEntry,
  getTeacherSectionListOfGradeEntry,
} from '../../api/requests/gradeEntry';
import { PeriodsLoader } from '../../components/LoaderContexts/PeriodsLoader';
import { showDialogInfo } from '../../components/Modals/Dialog';
import { SearchControls } from '../../components/SearchControls/SearchControls';
import SectionClosedModal from '../../components/SectionClosedModal/SectionClosedModal';
import TableEmptyContent from '../../components/Text/TableEmptyContent';
import { PathsLayouts } from '../../config/routes';
import { SearchParams } from '../../types/Filters';
import useValidatorNotes from './hooks/useValidatorNotes';

const ITEMS_PER_PAGE_DEFAULT = 10;

interface HandleSearch {
  searchParams?: SearchParams;
  page: number;
}

export default function GradeEntryList() {
  const { t } = useTranslation();
  const history = useHistory();

  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [openSectionClosedModal, setOpenSectionClosedModal] = useState<boolean>(
    false,
  );
  const [errorLoadedDataTable, setErrorLoadedDataTable] = useState<boolean>(
    false,
  );

  const [
    teacherSectionListOfGradeEntry,
    setTeacherSectionListOfGradeEntry,
  ] = useState<TeacherSectionListOfGradeEntry[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [filters, setFilters] = useState<SearchParams>({});

  const { errorLoading: errorLoadConfigNotes } = useValidatorNotes();

  const goCreateEvaluation = useCallback(
    (id: number) => {
      history.push(`${PathsLayouts.gradeEntry}/${id}/create-evaluation`);
    },
    [history],
  );

  const goAddEvaluation = useCallback(
    (id: number, key: number) => {
      if (errorLoadConfigNotes) {
        return showDialogInfo({
          icon: 'warning',
          title: 'No se ha configurado el promedio notas',
          subtitle:
            'Es necesario que se configura el promedio de la nota para ingresar las calificaciones',
        });
      } else if (teacherSectionListOfGradeEntry[key].finalGrade) {
        history.push(`${PathsLayouts.gradeEntry}/${id}/add-evaluation`);
      } else {
        showDialogInfo({
          icon: 'warning',
          title: t('gradeEntry.dialogInfo.title'),
          subtitle: t('gradeEntry.dialogInfo.subtitle'),
        });
      }
    },
    [history, teacherSectionListOfGradeEntry, t, errorLoadConfigNotes],
  );

  const columns = useMemo<ColumnTable<TeacherSectionListOfGradeEntry>[]>(() => {
    return [
      {
        headerText: t(`common.terms.period`),
        columnName: 'periodName',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: (options) => options.row.period?.name,
      },
      {
        headerText: t(`common.terms.schoolName`),
        columnName: 'schoolName',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: (options) => options.row.course?.school?.name,
      },
      {
        headerText: t(`common.terms.campusName`),
        columnName: 'campusName',
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: (options) => options.row.campus?.name,
      },
      {
        columnName: 'acronym',
        headerText: t('gradeEntry.columns.acronym'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        hideOnMobile: true,
        cellFormat: (options) => options.row.course.shortening,
      },
      {
        columnName: 'name',
        headerText: t('gradeEntry.columns.name'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: (options) => options.row.course.name,
      },
      {
        columnName: 'section',
        headerText: t('gradeEntry.columns.section'),
        thClassName: 'text-center',
        tdClassName: 'text-center',
        cellFormat: ({ row }) => row.name,
      },
      {
        columnName: 'action',
        headerText: t('gradeEntry.columns.action'),
        width: '40%',
        thClassName: 'text-center',
        cellFormat: ({ row, index }) => {
          const textEvaluation = !row.finalGrade
            ? t('gradeEntry.columns.menu.createEvaluation')
            : t('gradeEntry.columns.menu.editEvaluation');

          const textGrades = Boolean(row.closedAt)
            ? t('gradeEntry.columns.menu.seeRating')
            : t('gradeEntry.columns.menu.enterRating');
          return (
            <Row>
              <Col className="mb-2">
                <Button
                  text={textEvaluation}
                  outlined
                  size="sm"
                  fullwidth
                  disabled={Boolean(row.closedAt)}
                  onClick={() => {
                    if (row.closedAt) {
                      setOpenSectionClosedModal(true);
                    } else {
                      goCreateEvaluation(row.id);
                    }
                  }}
                />
              </Col>
              <Col className="mb-2">
                <Button
                  text={textGrades}
                  size="sm"
                  fullwidth
                  onClick={() => {
                    goAddEvaluation(row.id, index);
                  }}
                  style={{ height: '100%' }}
                />
              </Col>
            </Row>
          );
        },
      },
    ];
  }, [t, goCreateEvaluation, goAddEvaluation]);

  const handleSearch = useCallback(
    async ({ searchParams, page = 0 }: HandleSearch) => {
      setLoadingTable(true);
      let { error, data } = await getTeacherSectionListOfGradeEntry({
        page,
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        periodId: searchParams?.periodId,
        schoolId: searchParams?.schoolId,
        campusId: searchParams?.campusId,
        searchSection: searchParams?.searchSection,
        sectionStatus: searchParams?.sectionStatus,
        // hasFilters: searchParams?.hasFilters,
      });

      if (error) {
        setErrorLoadedDataTable(true);
      }

      if (data) {
        setErrorLoadedDataTable(false);
        setTeacherSectionListOfGradeEntry(data.data ?? []);
        setTotalItems(data.total);
        setTotalPages(data.total_pages);
      }
      setLoadingTable(false);
    },
    [setLoadingTable, setErrorLoadedDataTable],
  );

  const noResultsText = useMemo(() => {
    let title, subtitle, icon: IconNameType;
    if (errorLoadedDataTable) {
      title = t('gradeEntry.connection.title');
      subtitle = t('gradeEntry.connection.subtitle');
      icon = 'error';
    } else {
      icon = 'information';
      title = t('gradeEntry.notResult.title');
      subtitle = t('gradeEntry.notResult.subtitle');
    }
    return <TableEmptyContent icon={icon} title={title} subtitle={subtitle} />;
  }, [t, errorLoadedDataTable]);

  const handleClear = async () => {
    setTeacherSectionListOfGradeEntry([]);
    setFilters({});
    setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch({ searchParams: filters, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSearch, currentPage]);

  return (
    <PeriodsLoader>
      {({ data }) => (
        <>
          <SectionClosedModal
            isOpen={openSectionClosedModal}
            onConfirm={() => setOpenSectionClosedModal(false)}
            descriptionType="gradesEntry"
          />

          <div className="m-3 py-4 px-3 bg-white rounded">
            <h2 className="text-primary fs-20 fw-700 mt-2">
              {t('gradeEntry.title')}
            </h2>
            <p className="fs-16 mt-3 mb-4">{t('gradeEntry.subtitle')}</p>

            <SearchControls
              periods={data.periods}
              onSearch={handleSearch}
              onClear={handleClear}
              setFilters={setFilters}
            />

            <Table
              columns={columns}
              data={teacherSectionListOfGradeEntry}
              isLoadingResults={loadingTable}
              noResultsText={noResultsText}
              pagination={{
                currentPage: currentPage + 1,
                totalItems,
                totalPages,
                itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
                onChangePage: (page: number) => setCurrentPage(page - 1),
              }}
            ></Table>
          </div>
        </>
      )}
    </PeriodsLoader>
  );
}
