import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useTexts = () => {
  const { t } = useTranslation();
  const prefix = 'gradeEntry';

  const texts = useMemo(
    () => ({
      form: {
        title: t(`${prefix}.createEvaluation.title`),
        subtitle: t(`${prefix}.createEvaluation.subtitle`),
        fields: {
          nameEvaluation: t(
            `${prefix}.partialEvaluationGradeEntry.columns.placeholder.nameEvaluation`,
          ),
          nameSubEvaluation: t(
            `${prefix}.partialEvaluationGradeEntry.columns.placeholder.nameSubEvaluation`,
          ),
          limitDate1: t(
            `${prefix}.partialEvaluationGradeEntry.columns.placeholder.limitDate1`,
          ),
          limitDate2: t(
            `${prefix}.partialEvaluationGradeEntry.columns.placeholder.limitDate2`,
          ),
          surrencerDate: t(
            `${prefix}.partialEvaluationGradeEntry.columns.placeholder.surrencerDate`,
          ),
          percentage: t(`${prefix}.createEvaluation.form.fields.percentage`),
        },
      },
      info: {
        msgInfoFixed: t(`${prefix}.createEvaluation.msgInfoFixed`),
        msgInfoSemiFixed: t(`${prefix}.createEvaluation.msgInfoSemiFixed`),
      },
      table: {
        evaluationsTable: {
          columns: {
            evaluation: t(
              `${prefix}.partialEvaluationGradeEntry.columns.evaluation`,
            ),
            date: t(`common.terms.date`),
            percentage: t(
              `${prefix}.partialEvaluationGradeEntry.columns.percentage`,
            ),
            limitDate: (date: string) => {
              return t(
                `${prefix}.partialEvaluationGradeEntry.columns.limitDate`,
                {
                  date,
                },
              );
            },
          },
        },
        subEvaluationsTable: {
          columns: {
            name: t(`${prefix}.partialEvaluationGradeEntry.columns.evaluation`),
            date: t(`common.terms.date`),
            percentage: t(
              `${prefix}.partialEvaluationGradeEntry.columns.percentage`,
            ),
          },
        },
      },
      errors: {
        charLimit: t(`${prefix}.partialEvaluationGradeEntry.charLimit`),
        notValidDate: t(`${prefix}.notValidDate`),
        require: t(`${prefix}.require`),
        nameDuplicated: t(
          `${prefix}.partialEvaluationGradeEntry.duplicationName`,
        ),
      },
      btn: {
        deleteEvaluation: t(
          `${prefix}.createEvaluation.actions.deleteEvaluation`,
        ),
        addSubEvaluation: t(
          `${prefix}.createEvaluation.actions.addSubEvaluation`,
        ),
        deleteSubEvaluation: t(
          `${prefix}.createEvaluation.actions.deleteSubEvaluation`,
        ),
      },
      modals: {
        dialog: {
          title: t(`${prefix}.partialEvaluationGradeEntry.dialogInfo.title`),
          transformTitle: t(
            `${prefix}.partialEvaluationGradeEntry.dialogInfo.transformTitle`,
          ),
          subtitle: t(
            `${prefix}.partialEvaluationGradeEntry.dialogInfo.subtitle`,
          ),
        },
      },
    }),
    [t, prefix],
  );

  return texts;
};
