import { ReactElement, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import DisplayError from '../../../components/Info/DisplayError';
import Loading from '../../../components/Info/Loading';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import { getSectionGradeConfig } from '../requests';
import {
  EvaluationType,
  PartialEvaluation,
  PartialEvaluationSubevaluation,
  SectionsGradeConfig,
} from '../types';
import handleJson from '../utils/handleJson';
import { AxiosResultDefaultError } from '../../../api/request';
import { isSubevaluation } from '../parts/EvaluationForm/utils/validEvaluation';

export type AuthenticationError = {
  code: 'AUTHENTICATION';
  error: any;
  status: number;
  data?: any;
};

export interface CreateEvaluationGradeEntryBaseLoaderProps<T> {
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  SectionsGradeConfig,
  AuthenticationError | AxiosResultDefaultError
>();

export const CreateEvaluationGradeEntryBaseLoaderConsumer = FetchConsumer;

export const useCreateEvaluationGradeEntryBase = useFetch;

export default function CreateEvaluationGradeEntryBaseLoader({
  children,
}: CreateEvaluationGradeEntryBaseLoaderProps<SectionsGradeConfig>) {
  const { id: sectionId } = useParams<{
    id: string;
  }>();

  const request = useCallback(async () => {
    const response = await getSectionGradeConfig(sectionId);

    if (response.error) {
      return { error: response.error };
    }

    const evaluations: PartialEvaluation[] = [];
    const groups: Record<
      number,
      Omit<PartialEvaluation, 'type'> & {
        grades: PartialEvaluationSubevaluation[];
      }
    > = {};

    // First Edit StructureType
    if (!response.data?.gradesConfig?.testGrades?.length) {
      const evaluationsConfig = response.data.sectionData;
      if (
        evaluationsConfig?.StructureTypeSchema_finalEvaluationsConfig &&
        evaluationsConfig?.StructureTypeSchema_partialEvaluationsConfig
      ) {
        return {
          data: {
            sectionData: evaluationsConfig,
            gradesConfig: {
              finalGrade: handleJson(
                evaluationsConfig.StructureTypeSchema_finalEvaluationsConfig,
              ),
              testGrades: handleJson(
                evaluationsConfig.StructureTypeSchema_partialEvaluationsConfig,
              ),
            },
          },
        };
      }
    }

    // Edit StructureType
    for (const testGrade of response.data?.gradesConfig?.testGrades ?? []) {
      let canItBeRemoved = testGrade.studentTestGrades
        ? !testGrade.studentTestGrades.some(({ grade }) => grade)
        : true;
      if (testGrade.group) {
        if (groups[testGrade.group.id]) {
          const group = groups[testGrade.group.id];
          if (!canItBeRemoved) {
            group.canItBeRemoved = false;
          }
          group.grades.push({
            id: testGrade.id,
            name: testGrade.name,
            date: testGrade.date,
            percentage: `${testGrade.percentage}`,
            orderIndex: testGrade?.orderIndex,
            canItBeRemoved,
          });
        } else {
          const group: PartialEvaluation = {
            type: EvaluationType.Subevaluations,
            id: testGrade.group.id,
            name: testGrade.group.name,
            limitDate: testGrade.group?.limitDate,
            hasLimitDate: testGrade.group?.hasLimitDate,
            percentage: `${testGrade.group.percentage}`,
            orderIndex: testGrade?.orderIndex,
            canItBeRemoved,
            grades: [
              {
                id: testGrade.id,
                name: testGrade.name,
                date: testGrade.date,
                percentage: `${testGrade.percentage}`,
                orderIndex: testGrade?.orderIndex,
                canItBeRemoved,
              },
            ],
          };
          groups[testGrade.group.id] = group;
          evaluations.push(group);
        }
      } else {
        evaluations.push({
          type: EvaluationType.Normal,
          id: testGrade.id,
          name: testGrade.name,
          date: testGrade.date,
          limitDate: testGrade?.limitDate,
          hasLimitDate: testGrade?.hasLimitDate,
          percentage: `${testGrade.percentage}`,
          orderIndex: testGrade?.orderIndex,
          canItBeRemoved,
        });
      }
    }

    return {
      data: {
        sectionData: response.data.sectionData,
        gradesConfig: {
          finalGrade: {
            id: response.data.gradesConfig.finalGrade?.id ?? undefined,
            partialPercentage: response.data.gradesConfig.finalGrade?.testGrade,
            examPercentage: response.data.gradesConfig.finalGrade?.finalTest,
          },
          /* NOTA: Evaluations Sorting is by dates and not by orderIndex.
           But the orderIndex is not used in the frontend now, so if it 
           is needed, it can be added in the future to sort by orderIndex
          */
          testGrades: evaluations,
        },
      },
    };
  }, [sectionId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return <Loading insideCard />;
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
