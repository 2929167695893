import { formatDate } from '../../../../../utils/dates';
import {
  PartialEvaluation,
  PartialEvaluationNormal,
  PartialEvaluationSubevaluation,
  PartialEvaluationSubevaluations,
} from '../../../types';
import sumPercentage from './sumPercentage';

function isValidGrades(
  grades: { percentage?: string; name?: string }[],
  options: { minLength: number },
) {
  const minLengthValid = grades.length >= options.minLength;
  const percentage = sumPercentage(grades);
  const percentageSumValid = percentage === 100;
  const names = grades
    .map((e) => e.name)
    .filter((item): item is string => !!item);
  const nameDuplicated = !isSomeDuplicated(names);
  return minLengthValid && percentageSumValid && nameDuplicated;
}

export function isValidEvaluations(evaluations: PartialEvaluation[]) {
  const evaluationsValid = evaluations.every((evaluation) => {
    if (isSubevaluation(evaluation)) {
      return isValidEvaluationSubevaluations(evaluation);
    } else if (isNormal(evaluation)) {
      return isValidEvaluationNormal(evaluation);
    } else {
      throw new Error('tipo no definido');
    }
  });
  const gradesValid = isValidGrades(evaluations, { minLength: 1 });
  if (evaluationsValid && gradesValid) {
    return true;
  }
  return false;
}

export function isValidEvaluationNormal(evaluation: PartialEvaluationNormal) {
  const percentageValid = isPercentageValid(evaluation.percentage);
  const nameValid = isNameValid(evaluation.name);
  const dateValid = evaluation?.hasLimitDate
    ? isBeforeLimitDate(evaluation, evaluation)
    : isDateValid(evaluation.date);
  if (percentageValid && nameValid && dateValid) {
    return true;
  }
  return false;
}

export function isValidEvaluationSubevaluation(
  evaluation: PartialEvaluationSubevaluation,
) {
  const percentageValid = isPercentageValid(evaluation.percentage);
  const nameValid = isNameValid(evaluation.name);
  const dateValid = isDateValid(evaluation.date);
  if (percentageValid && nameValid && dateValid) {
    return true;
  }
  return false;
}

export function isValidEvaluationSubevaluations(
  evaluation: PartialEvaluationSubevaluations,
) {
  const percentageValid = isPercentageValid(evaluation.percentage);
  const nameValid = isNameValid(evaluation.name);
  const validGrades = isValidGrades(evaluation.grades, { minLength: 2 });
  const validSubevaluations = evaluation.grades.every((subevaluation) =>
    isValidEvaluationSubevaluation(subevaluation),
  );
  const validSubevaluationsDates = validateSubevaluationsDates(evaluation);
  return (
    percentageValid &&
    nameValid &&
    validGrades &&
    validSubevaluations &&
    validSubevaluationsDates
  );
}

function isSomeDuplicated(data: string[]) {
  return data.some((element, index) => {
    return data.indexOf(element) !== index;
  });
}

function isNameValid(name?: string) {
  if (!name) {
    return false;
  }
  if (name.length > 30) {
    return false;
  }
  return true;
}

function isPercentageValid(percentage?: string) {
  if (!percentage) {
    return false;
  }
  return true;
}

function isDateValid(date?: string | Date | null) {
  if (!date) {
    return false;
  }
  return true;
}

export function isSubevaluation(evaluation: {
  type: string;
}): evaluation is PartialEvaluationSubevaluations {
  return evaluation.type === 'subevaluations';
}

export function isNormal(evaluation: {
  type: string;
}): evaluation is PartialEvaluationNormal {
  return evaluation.type === 'normal';
}

function validateSubevaluationsDates(
  evaluation: PartialEvaluationSubevaluations,
) {
  return evaluation?.hasLimitDate
    ? evaluation.grades.every((subevaluation) =>
        isBeforeLimitDate(evaluation, subevaluation),
      )
    : true;
}

export function isBeforeLimitDate(
  parentEvaluation: PartialEvaluationSubevaluations | PartialEvaluationNormal,
  childEvaluation: PartialEvaluationSubevaluation,
) {
  return (
    parentEvaluation?.limitDate &&
    childEvaluation?.date &&
    !(
      formatDate(childEvaluation?.date, 'YYYY-MM-DD') >
      formatDate(parentEvaluation?.limitDate, 'YYYY-MM-DD')
    )
  );
}
