import { useTexts } from '../../hooks/useTextsContext';
import useStructureTypeIsFixed from '../EvaluationForm/hooks/useStructureTypeIsFixed';
import HeaderStructureType from './HeaderStructureType';

const CreateEvaluationGradeEntryHeader = () => {
  const texts = useTexts();
  const {
    isFreeType,
    isSemiFixedType,
    isFixedType,
  } = useStructureTypeIsFixed();

  return (
    <>
      {isFreeType && <HeaderStructureType />}

      {isSemiFixedType && (
        <HeaderStructureType msgInfo={texts.info.msgInfoSemiFixed} />
      )}

      {isFixedType && <HeaderStructureType msgInfo={texts.info.msgInfoFixed} />}
    </>
  );
};

export default CreateEvaluationGradeEntryHeader;
