import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';

import GoBackButton from '../../components/Button/GoBackButton';
import { PathsLayouts } from '../../config/routes';
import CreateEvaluationGradeEntryBaseLoader from './Loaders/CreateEvaluationGradeEntryBaseLoader';
import CreateEvaluationGradeEntryForm from './parts/CreateEvaluationGradeEntryForm';
import CreateEvaluationGradeEntryInfoTags from './parts/CreateEvaluationGradeEntryInfoTags';
import { CreateEvaluationFormContextProvider } from './parts/EvaluationForm/context/CreateEvaluationFormContext';
import CreateEvaluationGradeEntryHeader from './parts/Header';

export default function GradeEntryCreateEvaluation() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const backToListGradeList = useCallback(() => {
    history.push(`${PathsLayouts.gradeEntry}`);
  }, [history]);

  return (
    <CreateEvaluationGradeEntryBaseLoader>
      {({ data, refresh }) => (
        <CreateEvaluationFormContextProvider
          gradesConfig={{
            finalGrade: data.gradesConfig.finalGrade ?? {},
            testGrades: data.gradesConfig.testGrades ?? [],
          }}
        >
          <div className="m-3 py-4 px-3 bg-white rounded">
            <Row className="mb-4">
              <Col className="mb-4">
                <GoBackButton onClick={backToListGradeList} />
              </Col>
              <Col md={6}>
                <CreateEvaluationGradeEntryInfoTags data={data.sectionData} />
              </Col>
            </Row>

            <CreateEvaluationGradeEntryHeader />
            <CreateEvaluationGradeEntryForm sectionId={id} refresh={refresh} />
          </div>
        </CreateEvaluationFormContextProvider>
      )}
    </CreateEvaluationGradeEntryBaseLoader>
  );
}
