import {
  Button,
  CellFormatOptions,
  ColumnTable,
  Icon,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GradeEntry,
  StudentTestGrade,
  TestGrades,
  TestGradesGroup,
} from '../../../api/requests/gradeEntry';
import { PARTIALGRADEGROUP, SUBEVALUATION } from '../utils/isColumn';
import { StudentProps } from '../parts/AddEvaluationGrade/AddEvaluationGradeEntry';
import AddEvaluationGradeEntryFinalGradeCell from '../parts/AddEvaluationGrade/AddEvaluationGradeEntryFinalGradeCell';

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export interface AddEvaluationGradeEntryColumnsProps {
  isClosed: boolean;
  partiaGradesGroupEvaluation: TestGradesGroup;
  partiaGradesEvaluation: GradeEntry['testGrades'];
  selectedNoteExamen: boolean;
  config: {
    max: number;
    min: number;
    decimalsLength: number;
    pass: number;
  };
  addTextWithAnotherZero: (percentage: number) => string | number;
  search: {
    value: string | undefined;
    onChangeSearch: (e: any) => void;
    studentFilteredBySearch: (students: StudentProps[]) => StudentProps[];
    onClearSearch: () => void;
    notResult: JSX.Element;
  };
  finalTestEvaluation: number;
  testGrade: number;
  students: StudentProps[];
  postStudentTestGradeRequest: (
    testGradeId: number,
    index: number,
  ) => Promise<React.ReactText | undefined>;
  setStudents: React.Dispatch<React.SetStateAction<StudentProps[]>>;
  setSelectNoteExamen: React.Dispatch<React.SetStateAction<boolean>>;
  findStudentGrade: (
    studentID: any,
    testGradeID: any,
  ) =>
    | {
        grade: StudentTestGrade;
        studentIndex: number;
        gradeIndex: number;
      }
    | undefined;
  postStudentsFinalGradeRequest: () => Promise<void>;
  formattertNote: (value: string) => string;
}

export default function useAddEvaluationGradeEntryColumns({
  isClosed,
  partiaGradesGroupEvaluation,
  partiaGradesEvaluation,
  selectedNoteExamen,
  config,
  addTextWithAnotherZero,
  search,
  finalTestEvaluation,
  testGrade,
  students,
  postStudentTestGradeRequest,
  setStudents,
  setSelectNoteExamen,
  findStudentGrade,
  postStudentsFinalGradeRequest,
  formattertNote,
}: AddEvaluationGradeEntryColumnsProps) {
  const { t } = useTranslation();

  const headerTextStyles = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column' as FlexDirection,
      height: '100%',
    }),
    [],
  );

  const addtTextWithColor = useCallback(
    (percentage: string) => {
      let color;
      if (
        percentage.toLocaleLowerCase() === 'n' ||
        percentage.toLocaleLowerCase() === 'nr' ||
        percentage.toLocaleLowerCase() === 'j'
      ) {
        color = 'danger';
      } else {
        color = Number(percentage) >= config.pass ? 'primary' : 'danger';
      }
      return (
        <p className={`text-${color} mb-0 text-uppercase`}>{percentage}</p>
      );
    },
    [config],
  );

  const calculatedTotalFinalNote = useMemo(() => {
    let count = students.reduce((total, studentNote) => {
      if (studentNote.noteExamen) {
        return total + 1;
      } else {
        return total;
      }
    }, 0);
    return addTextWithAnotherZero(count);
  }, [students, addTextWithAnotherZero]);

  const [selectedColumn, setSelectedColumn] = useState<number>(-1);

  const hiddenColumns = useMemo(() => {
    return selectedColumn !== -1 || selectedNoteExamen ? 'hiddenColumn' : '';
  }, [selectedColumn, selectedNoteExamen]);

  const disableButtons = useMemo(() => {
    return students.length === 0;
  }, [students]);

  const onChangeNotesOfStudent = useCallback(
    (i: number, j: number) => {
      return (e: any) => {
        let value = formattertNote(e.target.value);
        if (value || !e.target.value) {
          if (!students[i].notes[j]) {
            students[i].notes[j] = {};
          }
          students[i].notes[j].studentTestGradesGrade = value;
          setStudents([...students]);
        }
      };
    },
    [students, setStudents, formattertNote],
  );

  const generateColumnParcialGroup = useCallback(
    (parcialGroupId: number) => {
      const parcialGroup = partiaGradesGroupEvaluation.find(
        (parcialGroup) => parcialGroup.id === parcialGroupId,
      );
      return {
        headerText: (
          <div style={headerTextStyles}>
            <p className="mb-1 mt-2 text-capitalize strong">
              {t('gradeEntry.addEvaluation.total')}
            </p>
            <p className="mb-1 text-capitalize">
              {t('gradeEntry.addEvaluation.pon')}: {parcialGroup?.percentage}%
            </p>
            <p></p>
          </div>
        ),
        columnName: `${PARTIALGRADEGROUP}-${parcialGroupId}-${parcialGroup?.id}`,
        thClassName: `text-center`,
        tdClassName: `text-center`,
        width: '140px',
        cellFormat: (options: CellFormatOptions<StudentProps>) => {
          const parcialGroupStudent = options.row.testGradeGroup.find(
            (parcialGroup) => parcialGroup.id === parcialGroupId,
          );
          if (parcialGroupStudent?.grade) {
            return addtTextWithColor(String(parcialGroupStudent?.grade));
          }
          return '';
        },
      };
    },
    [addtTextWithColor, headerTextStyles, partiaGradesGroupEvaluation, t],
  );

  const generateColumnToNormalOrGroupGrade = useCallback(
    (key: number, testGrade: TestGrades): ColumnTable => {
      //  'Normal' // 'Normal Group';
      const { id, name, date, percentage, groupId } = testGrade;
      let hiddenStyle =
        (selectedColumn !== key && selectedColumn !== -1) || selectedNoteExamen
          ? 'hiddenColumn'
          : '';

      const columnName = groupId
        ? `${SUBEVALUATION}-${key}-${groupId}`
        : `evaluation-${key}`;

      const count = partiaGradesEvaluation[key].studentTestGrades.reduce(
        (total, studentTestGrade) =>
          studentTestGrade?.grade ? total + 1 : total,
        0,
      );

      return {
        columnName: columnName,
        headerText: (
          <div style={headerTextStyles}>
            <p className="mb-1 mt-1 text-capitalize">
              {name} ({percentage}%)
            </p>
            <div>
              <p className="mb-1 text-capitalize">
                <br />
                {dayjs(date as Date)
                  .utc()
                  .format('DD/MM/YYYY')}
              </p>
              <br />
              <p className="mb-1 text-capitalize">
                {addTextWithAnotherZero(count)}/
                {addTextWithAnotherZero(students.length)}{' '}
                <Icon name="person_record" color="primary" size="12px" />
              </p>
            </div>
            {!hiddenStyle && (
              <Button
                text={t(`common.terms.${hiddenColumns ? 'save' : 'getInto'}`)}
                size="sm"
                disabled={disableButtons || isClosed}
                onClick={() => {
                  if (disableButtons || isClosed) {
                    return;
                  }
                  if (selectedColumn !== -1) {
                    postStudentTestGradeRequest(id as number, key);
                  }
                  setSelectedColumn((state) => (state === -1 ? key : -1));
                }}
              />
            )}
          </div>
        ),
        thClassName: `text-center `,
        tdClassName: ``,
        cellFormat: (options: CellFormatOptions<StudentProps>) => {
          let grade = findStudentGrade(options.row.studentId, id);
          if (!grade?.grade) {
            students[options.index as number].notes.push({
              studentTestGradesGrade: '',
              testGradeId: id as number,
            });
            setStudents([...students]);
          }
          return (
            <div className="d-flex justify-content-center">
              {grade?.grade?.studentTestGradesGrade && selectedColumn === -1 ? (
                addtTextWithColor(
                  String(grade?.grade?.studentTestGradesGrade || ''),
                )
              ) : (
                <input
                  className="text-center text-uppercase border-input"
                  type="text"
                  disabled={selectedColumn !== key}
                  value={`${grade?.grade?.studentTestGradesGrade || ''}`}
                  onChange={onChangeNotesOfStudent(
                    grade?.studentIndex as number,
                    grade?.gradeIndex as number,
                  )}
                  style={{
                    maxWidth: '89px',
                    height: '36px',
                  }}
                />
              )}
            </div>
          );
        },
      };
    },
    [
      isClosed,
      addTextWithAnotherZero,
      addtTextWithColor,
      disableButtons,
      findStudentGrade,
      headerTextStyles,
      hiddenColumns,
      onChangeNotesOfStudent,
      partiaGradesEvaluation,
      postStudentTestGradeRequest,
      selectedColumn,
      selectedNoteExamen,
      setStudents,
      students,
      t,
    ],
  );

  const createColumnsGradesEvaluation = useCallback(() => {
    const result: ColumnTable[] = [];
    let previousGroupId: number | null = null;

    for (let index = 0; index < partiaGradesEvaluation.length; index++) {
      const dat = partiaGradesEvaluation[index];
      if (
        (dat.groupId === null && previousGroupId !== null) ||
        (dat.groupId &&
          previousGroupId !== null &&
          previousGroupId !== dat.groupId)
      ) {
        // 'Parcial Group';
        result.push(generateColumnParcialGroup(previousGroupId));
        // 'Normal or Group';
        result.push(generateColumnToNormalOrGroupGrade(index, dat));
      } else {
        // 'Normal or Group';
        result.push(generateColumnToNormalOrGroupGrade(index, dat));
      }

      if (index === partiaGradesEvaluation.length - 1 && dat.groupId) {
        // 'Parcial Group';
        result.push(generateColumnParcialGroup(dat.groupId));
      }
      previousGroupId = dat.groupId ?? null;
    }
    return result;
  }, [
    generateColumnParcialGroup,
    generateColumnToNormalOrGroupGrade,
    partiaGradesEvaluation,
  ]);

  return useMemo<ColumnTable[]>(() => {
    const columns = [
      {
        columnName: 'names',
        headerText: t('gradeEntry.addEvaluation.nameAlumnsAndRut'),
        thClassName: 'text-center column-sticky bg-secondary',
        tdClassName: 'text-center column-sticky bg-white',
        width: '200px',
        cellFormat: ({ row }: CellFormatOptions<StudentProps>) => {
          return `${row.fullname} ${row.id}`;
        },
      },
      ...createColumnsGradesEvaluation(),
    ];

    if (finalTestEvaluation) {
      columns.push(
        {
          headerText: (
            <div style={headerTextStyles}>
              <p className="mb-1 mt-2 text-capitalize strong">
                {t('gradeEntry.addEvaluation.notes')}{' '}
                {t('gradeEntry.addEvaluation.partials')}
              </p>
              <p className="mb-1 text-capitalize">
                {t('gradeEntry.addEvaluation.pon')}: {testGrade}%
              </p>
              <p></p>
            </div>
          ),
          columnName: 'partialEvaluation',
          thClassName: `text-center`,
          tdClassName: `text-center`,
          width: '140px',
          cellFormat: (options: CellFormatOptions<StudentProps>) => {
            if (options.row.testGrade) {
              return addtTextWithColor(String(options.row.testGrade));
            }
            return '';
          },
        },
        {
          columnName: `finalGrade`,
          headerText: (
            <div style={headerTextStyles}>
              <p>
                {t('gradeEntry.addEvaluation.note')}
                <br />
                {t('gradeEntry.addEvaluation.test')}
                <br />
              </p>
              <p>
                {t('gradeEntry.addEvaluation.pon')}: {finalTestEvaluation}%
                <br />
              </p>
              <p>
                {calculatedTotalFinalNote}/
                {addTextWithAnotherZero(students.length)}
                <Icon name="person_record" color="primary" size="12px" />
              </p>
              {hiddenColumns && !selectedNoteExamen ? (
                <></>
              ) : (
                <Button
                  text={t(
                    `common.terms.${selectedNoteExamen ? 'save' : 'getInto'}`,
                  )}
                  size="sm"
                  disabled={disableButtons || isClosed}
                  onClick={() => {
                    if (disableButtons || isClosed) {
                      return;
                    }
                    if (selectedNoteExamen) {
                      postStudentsFinalGradeRequest();
                    }
                    setSelectNoteExamen(!selectedNoteExamen);
                  }}
                />
              )}
            </div>
          ),
          thClassName: `text-center`,
          tdClassName: `text-center`,
          cellFormat: (options: CellFormatOptions<StudentProps>) => {
            let percentage = options.row.noteExamen;
            return (
              <AddEvaluationGradeEntryFinalGradeCell
                percentage={percentage}
                formattertNote={formattertNote}
                addtTextWithColor={addtTextWithColor}
                selectedNoteExamen={selectedNoteExamen}
                onChange={(value) => {
                  const studentInFiltered = search.studentFilteredBySearch(
                    students,
                  )[options.index];

                  const studentIdxInFullList = students.findIndex(
                    (st) => st.id === studentInFiltered.id,
                  );
                  const newStudent = {
                    ...studentInFiltered,
                    noteExamen: value.toLocaleUpperCase(),
                  };

                  setStudents(() => {
                    return [
                      ...students.slice(0, studentIdxInFullList),
                      newStudent,
                      ...students.slice(studentIdxInFullList + 1),
                    ];
                  });
                }}
              />
            );
          },
        },
      );
    }

    columns.push({
      columnName: 'endCourseOfGrade',
      headerText: t('gradeEntry.addEvaluation.finalGrade'),
      thClassName: `text-center`,
      tdClassName: `text-center`,
      width: '140px',
      cellFormat: (options: CellFormatOptions<StudentProps>) => {
        if (options.row.finalGrade) {
          return addtTextWithColor(String(options.row.finalGrade));
        }
        return '';
      },
    });

    return columns;
  }, [
    isClosed,
    search,
    addtTextWithColor,
    finalTestEvaluation,
    t,
    students,
    addTextWithAnotherZero,
    testGrade,
    hiddenColumns,
    setStudents,
    setSelectNoteExamen,
    selectedNoteExamen,
    formattertNote,
    calculatedTotalFinalNote,
    disableButtons,
    postStudentsFinalGradeRequest,
    createColumnsGradesEvaluation,
    headerTextStyles,
  ]);
}
