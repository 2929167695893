import { StackGradesGroup } from '../parts/AddEvaluationGrade/AddEvaluationGradeEntry';
import { isColumnSubEvaluation } from './isColumn';

const getIdFromColumnSubEvaluation = (columnName: string) => {
  const POSITION_ID_GROUP = 2;
  if (isColumnSubEvaluation(columnName)) {
    return columnName.split('-')[POSITION_ID_GROUP];
  }
};

export const printHeaderSubEvaluation = (
  columnName: string,
  StackGradesGroup: StackGradesGroup,
) => {
  const idGradeGroup = getIdFromColumnSubEvaluation(columnName);
  if (!idGradeGroup || !StackGradesGroup[idGradeGroup]) {
    return;
  }

  StackGradesGroup[idGradeGroup].stack += 1;
  if (
    StackGradesGroup[idGradeGroup].stack ===
    StackGradesGroup[idGradeGroup].length
  ) {
    return (
      <th
        className="text-center"
        key={columnName}
        rowSpan={1} // columns
        colSpan={StackGradesGroup[idGradeGroup].length + 1} // rows
      >
        {StackGradesGroup[idGradeGroup].name} (
        {StackGradesGroup[idGradeGroup].percentage}%)
      </th>
    );
  }
};

export const printHeaderGeneral = (column: any) => (
  <th className={column.thClassName} key={column.columnName} rowSpan={2}>
    {column.headerText}
  </th>
);
