import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';

import GoBackButton from '../../../../components/Button/GoBackButton';
import InfoTags, {
  InfoTagsProps,
} from '../../../../components/Header/InfoTags';
import { PathsLayouts } from '../../../../config/routes';

export interface HeaderSectionOfGradeEntryProps {
  Course_shortening: string;
  Course_name: string;
  Section_name: string;
  Campus_name: string;
}

export default function HeaderSectionOfGradeEntry({
  Course_shortening,
  Course_name,
  Section_name,
  Campus_name,
}: HeaderSectionOfGradeEntryProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const tags = useMemo<InfoTagsProps['tags']>(() => {
    return [
      {
        label: t('common.terms.shortening'),
        value: Course_shortening,
      },
      {
        label: t('common.terms.course'),
        value: Course_name,
      },
      {
        label: t('common.terms.section'),
        value: Section_name,
      },
      {
        label: t('common.terms.campus'),
        value: Campus_name,
      },
    ];
  }, [t, Course_shortening, Course_name, Section_name, Campus_name]);

  const backToListGradeList = useCallback(() => {
    history.push(`${PathsLayouts.gradeEntry}`);
  }, [history]);

  return (
    <Row className="mb-4">
      <Col className="mb-4">
        <GoBackButton onClick={backToListGradeList} />
      </Col>
      <Col md={6}>
        <InfoTags tags={tags} />
      </Col>
    </Row>
  );
}
