import { TableCollapsable } from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import useStructureTypeIsFixed from '../../hooks/useStructureTypeIsFixed';
import { EvaluationType, PartialEvaluation } from './../../../../types';
import { useCreateEvaluationFormContext } from './../../context/CreateEvaluationFormContext';
import usePartialEvaluationGradeColumns from './../../hooks/usePartialEvaluationColumns';
import sumPercentage from './../../utils/sumPercentage';
import TotalPercentageFooter from './../TotalPercentageFooter';
import SubevaluationsTable from './SubevaluationsTable';

export interface PartialEvaluationGradeProps {
  onChange: (state: PartialEvaluation[]) => void;
  error?: boolean;
  partialEvaluations: PartialEvaluation[];
}

export default function EvaluationsTable() {
  const { t } = useTranslation();
  const { isFreeType } = useStructureTypeIsFixed();
  const {
    gradeEntry: { testGrades: partialEvaluations },
    onChangePartialEvaluationGradeEntry: onChange,
    handleError: { partialEvaluationGradeEntry: error },
  } = useCreateEvaluationFormContext();

  // Crud operations - Evaluations
  const addPartialEvaluation = useCallback(() => {
    onChange([
      ...partialEvaluations,
      { type: EvaluationType.Normal, canItBeRemoved: true },
    ]);
  }, [partialEvaluations, onChange]);

  const addPartialEvaluationSubevaluations = useCallback(() => {
    onChange([
      ...partialEvaluations,
      {
        type: EvaluationType.Subevaluations,
        canItBeRemoved: true,
        grades: [{ canItBeRemoved: true }, { canItBeRemoved: true }],
      },
    ]);
  }, [partialEvaluations, onChange]);

  const setPartialEvaluationByName = useCallback(
    (row: { name?: string }) => {
      return (e: any) => {
        row.name = e.target.value;
        onChange([...partialEvaluations]);
      };
    },
    [partialEvaluations, onChange],
  );

  const setPartialEvaluationByDate = useCallback(
    (row: { date?: string | Date | null }) => {
      return (value: any) => {
        if (value instanceof Date) {
          row.date = dayjs(value).format('YYYY-MM-DD');
        }
        row.date = value;
        onChange([...partialEvaluations]);
      };
    },
    [partialEvaluations, onChange],
  );

  const setPartialEvaluationByWeighting = useCallback(
    (row: { percentage?: string }) => {
      return (e: any) => {
        let value = e.target.value;
        if (
          Number(value) <= 100 &&
          Number(value) >= 0 &&
          value.search(/\D/) === -1
        ) {
          row.percentage = value;
          onChange([...partialEvaluations]);
        }
      };
    },
    [partialEvaluations, onChange],
  );

  const setPartialEvaluationByLimitDate = useCallback(
    (row: { limitDate?: string | Date | null }) => {
      return (value: any) => {
        if (value instanceof Date) {
          row.limitDate = dayjs(value).format('YYYY-MM-DD');
        }
        row.limitDate = value;
        onChange([...partialEvaluations]);
      };
    },
    [partialEvaluations, onChange],
  );

  const setPartialEvaluationByHasLimitDate = useCallback(
    (row: { hasLimitDate?: boolean }) => {
      return (e: any) => {
        row.hasLimitDate = e.target.checked;
        onChange([...partialEvaluations]);
      };
    },
    [partialEvaluations, onChange],
  );

  const columns = usePartialEvaluationGradeColumns({
    onChange,
    error,
    partialEvaluations,
    onNameChange: setPartialEvaluationByName,
    onDateChange: setPartialEvaluationByDate,
    onWeightingChange: setPartialEvaluationByWeighting,
    onHasLimitDateChange: setPartialEvaluationByHasLimitDate,
    onLimitDateChange: setPartialEvaluationByLimitDate,
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const percentage = sumPercentage(partialEvaluations);
  const percentageValid = percentage === 100;
  return (
    <>
      <Row className="mb-4">
        <Col>
          <h2 className="text-primary fs-20 fw-700 mt-2">
            {t('gradeEntry.partialEvaluationGradeEntry.title')}
          </h2>
        </Col>
        <Col sm="auto" lg="auto" xs="auto">
          {isFreeType && (
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                className="g-button"
                color="primary"
                size="md"
                caret
              >
                <span>
                  {t('gradeEntry.partialEvaluationGradeEntry.addEvaluation')}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={addPartialEvaluation}>
                  {t(
                    'gradeEntry.partialEvaluationGradeEntry.addEvaluationNormal',
                  )}
                </DropdownItem>
                <DropdownItem onClick={addPartialEvaluationSubevaluations}>
                  {t(
                    'gradeEntry.partialEvaluationGradeEntry.addEvaluationSubevaluations',
                  )}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </Col>
      </Row>
      <div className="mb-5 g-table-evaluations">
        <TableCollapsable<PartialEvaluation>
          columns={columns}
          isCollapsable={(row) => row.type === 'subevaluations'}
          striped={false}
          isStartCollapsed={() => true}
          secondColumn={{
            tdClassName: 'px-4',
            columnName: 'grades',
            headerText: 'subevaluations-table-header',
            cellFormat: ({ row, index }) => {
              return (
                <div style={{ marginRight: '-0.75rem' }}>
                  <SubevaluationsTable
                    partialEvaluation={row}
                    partialEvaluationIndex={index}
                  />
                </div>
              );
            },
          }}
          data={partialEvaluations}
          notResponsive
          noDefaultFoot
          footComponent={({ columns, data }) => (
            <TotalPercentageFooter
              dataLength={data.length}
              columnsLength={columns.length}
              error={error}
              percentageValid={percentageValid}
              percentage={percentage}
            />
          )}
        />
        {error && !percentageValid && (
          <p className="text-danger mt-2">
            {t('gradeEntry.createEvaluation.msgErrorTables.totalErrorOfTable')}
          </p>
        )}
      </div>
    </>
  );
}
