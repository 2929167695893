import {
  Alert,
  ColumnTable,
  SearchBox,
  Table,
  addToast,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Col, Row } from 'reactstrap';

import {
  Grade,
  GradeEntry,
  StudentGradeEntry,
  StudentsTestGrade,
  TestGradesGroup,
  getEvaluationGradeEntry,
  getStudentsOfGradeEntry,
  postStudentsFinalGrade,
  postStudentsTestGrade,
} from '../../../../api/requests/gradeEntry';
import { getSectionStatus } from '../../../../api/requests/sections';
import Loading from '../../../../components/Info/Loading';
import { gradeToFixed } from '../../../../utils/math';
import {
  isColumnParcialSubEvaluation,
  isColumnSubEvaluation,
} from '../../utils/isColumn';
import mapStudentsProps from '../../utils/mapStudentsProps';
import {
  printHeaderGeneral,
  printHeaderSubEvaluation,
} from '../../utils/printSubHeaders';
import sortByEvaluationName from '../../utils/sortEvaluations';
import AddEvaluationGradeEntryLoader from '../../Loaders/AddEvaluationGradeEntryLoader';
import AddEvaluationGradeEntryNotResult from './AddEvaluationGradeEntryNotResult';
import HeaderSectionOfGradeEntry from './HeaderSectionOfGradeEntry';
import useAddEvaluationGradeEntryColumns from '../../hooks/useAddEvaluationGradeEntryColumns';
import useSearchStudents from '../../hooks/useSearchStudents';
import useValidatorNotes from '../../hooks/useValidatorNotes';

export type StackGradesGroup = Record<
  string,
  { name?: string; percentage?: number; stack: number; length: number }
>;

export interface StudentProps {
  fullname: string;
  id: string;
  testGradeGroup: {
    id?: number | null;
    grade?: Grade | null;
    percentage?: number | null;
  }[];
  testGrade: string;
  finalGrade: string;
  noteExamen: string;
  notes: StudentGradeEntry['TestGrade'];
  finalGrades: StudentGradeEntry['FinalGrade'];
  studentId: number | null;
}

export default function AddEvaluationGradeEntry() {
  const { t } = useTranslation();
  const search = useSearchStudents();
  const { id } = useParams<{ id: string }>();
  const { formattertNote, config } = useValidatorNotes();

  const [loading, setLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [finalTestEvaluation, setFinalTestEvaluation] = useState(0);
  const [testGrade, setTestGrade] = useState(0);

  const [students, setStudents] = useState<StudentProps[]>([]);
  const [partiaGradesEvaluation, setPartiaGradesEvaluation] = useState<
    GradeEntry['testGrades']
  >([]);
  const [
    partiaGradesGroupEvaluation,
    setPartiaGradesGroupEvaluation,
  ] = useState<TestGradesGroup>([]);
  const [selectedNoteExamen, setSelectNoteExamen] = useState<boolean>(false);
  const [finalGradeEvaluation, setFinalGradeEvaluation] = useState<
    GradeEntry['finalGrade']
  >();
  const [sectionIsClosed, setSectionIsClosed] = useState<boolean>(false);

  const getSectionStatusOpenOrClosed = useCallback(async () => {
    setLoading(true);
    const { error, data } = await getSectionStatus(Number(id));
    if (error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: `Error al obtener el status de la seccion, vuelve a intentarlo`,
        style: { width: '550px' },
      });
    } else {
      setSectionIsClosed(Boolean(data));
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getSectionStatusOpenOrClosed();
  }, [getSectionStatusOpenOrClosed]);

  const getPartiaGradesEvaluation = useCallback(
    async (useLoader: boolean = true) => {
      if (useLoader) setLoading(true);

      let { data } = await getEvaluationGradeEntry(Number(id));

      if (data) {
        let { finalGrade, testGrades, testGradesGroup } = data.data;
        setFinalTestEvaluation(finalGrade?.finalTest ?? 0);
        setTestGrade(finalGrade?.testGrade ?? 0);
        setPartiaGradesEvaluation(sortByEvaluationName(testGrades ?? []));
        setPartiaGradesGroupEvaluation(testGradesGroup ?? []);
        setFinalGradeEvaluation({
          id: finalGrade?.id,
          finalTest: finalGrade?.finalTest ?? 0,
          testGrade: finalGrade?.testGrade ?? 0,
        });
        setLoading(false);
        return { finalGrade, testGrades };
      }
      setLoading(false);
    },
    [
      id,
      setFinalTestEvaluation,
      setTestGrade,
      setPartiaGradesEvaluation,
      setLoading,
    ],
  );

  const getStudentsOfGradeEntryRequest = useCallback(async () => {
    setTableLoading(true);
    let { data, error } = await getStudentsOfGradeEntry(Number(id));
    if (error) {
      setError(true);
      setTableLoading(false);
    } else if (data) {
      setError(false);
      let students = mapStudentsProps(data.data);
      setStudents([...students]);
      setTableLoading(false);
      return students;
    }
  }, [setStudents, id, setTableLoading]);

  const findStudentGrade = useCallback(
    (studentID, testGradeID) => {
      let studentIndex = students.findIndex(
        (student) => studentID === student.studentId,
      );
      if (studentIndex !== -1) {
        let gradeIndex = students[studentIndex].notes.findIndex(
          (grade) => grade.testGradeId === testGradeID,
        );
        return {
          grade: students[studentIndex].notes[gradeIndex],
          studentIndex,
          gradeIndex,
        };
      }
      return undefined;
    },
    [students],
  );

  const addTextWithAnotherZero = useCallback((percentage: number) => {
    if (percentage < 10) {
      return `0${percentage}`;
    }
    return percentage;
  }, []);

  const postStudentTestGradeRequest = useCallback(
    async (testGradeId: number, index: number) => {
      let studentsTestGrade = students.map(({ studentId }) => {
        let grade = findStudentGrade(studentId, testGradeId);
        return {
          id: grade?.grade?.studentTestGradesId,
          testGradeId: testGradeId,
          studentId,
          grade: gradeToFixed(
            grade?.grade?.studentTestGradesGrade,
            config.decimalsLength,
          ),
        };
      }) as StudentsTestGrade[];

      setTableLoading(true);
      let { error } = await postStudentsTestGrade([...studentsTestGrade]);

      if (error) {
        return addToast({
          icon: 'information',
          color: 'danger',
          text: `Error al guardar calificaciones de “${partiaGradesEvaluation[index].name}”, vuelve a intentarlo`,
          style: { width: '550px' },
        });
      }

      const data = await getPartiaGradesEvaluation(false);
      await getStudentsOfGradeEntryRequest();

      const count = data?.testGrades[index].studentTestGrades.reduce(
        (total, studentTestGrade) =>
          studentTestGrade?.grade ? total + 1 : total,
        0,
      );
      const formatedCount = addTextWithAnotherZero(count || 0);
      const formatedTotal = addTextWithAnotherZero(students.length);

      addToast({
        icon: 'check',
        color: 'success',
        text: `${formatedCount}/${formatedTotal} calificaciones guardadas con éxito para la “${partiaGradesEvaluation[index].name}”`,
        style: { width: '550px' },
      });
    },
    [
      students,
      partiaGradesEvaluation,
      getStudentsOfGradeEntryRequest,
      findStudentGrade,
      addTextWithAnotherZero,
      getPartiaGradesEvaluation,
      config.decimalsLength,
    ],
  );

  const postStudentsFinalGradeRequest = useCallback(async () => {
    let data = students.map(({ studentId, finalGrades, noteExamen }) => {
      return {
        id: finalGrades.studentFinalGradesId,
        studentId: studentId as number,
        finalGradeId: finalGradeEvaluation?.id as number,
        grade: gradeToFixed(noteExamen, config.decimalsLength),
      };
    });

    const { error } = await postStudentsFinalGrade(data);
    if (error) {
      addToast({
        icon: 'information',
        color: 'danger',
        text: `Error al guardar calificaciones de “Examen Final”, vuelve a intentarlo`,
        style: { width: '550px' },
      });
    } else {
      const data = await getStudentsOfGradeEntryRequest();

      const count = data?.reduce(
        (total, finalGrade) =>
          finalGrade?.finalGrades.studentFinalGradesGrade ? total + 1 : total,
        0,
      );
      const formatedCount = addTextWithAnotherZero(count ?? 0);
      const formatedTotal = addTextWithAnotherZero(students.length);

      addToast({
        icon: 'check',
        color: 'success',
        text: `${formatedCount}/${formatedTotal} calificaciones guardadas con éxito para la “Examen Final”`,
        style: { width: '550px' },
      });
    }
  }, [
    finalGradeEvaluation,
    students,
    getStudentsOfGradeEntryRequest,
    addTextWithAnotherZero,
    config.decimalsLength,
  ]);

  const getData = useCallback(async () => {
    await getPartiaGradesEvaluation();
    await getStudentsOfGradeEntryRequest();
  }, [getPartiaGradesEvaluation, getStudentsOfGradeEntryRequest]);

  const columns = useAddEvaluationGradeEntryColumns({
    isClosed: sectionIsClosed,
    partiaGradesGroupEvaluation,
    partiaGradesEvaluation,
    selectedNoteExamen,
    addTextWithAnotherZero,
    search,
    config,
    finalTestEvaluation,
    testGrade,
    students,
    postStudentTestGradeRequest,
    setStudents,
    setSelectNoteExamen,
    findStudentGrade,
    postStudentsFinalGradeRequest,
    formattertNote,
  });

  useEffect(() => {
    getData();
  }, [getData]);

  // Stack usado para saber cuantas veces se debe
  // pintar el titulo del doble header para SubEvaluaciones
  const getStackGradesGroupEvaluation = useCallback(() => {
    const accumulator: StackGradesGroup = {};
    for (const gradeEvaluation of partiaGradesEvaluation) {
      if (gradeEvaluation.groupId)
        if (!(gradeEvaluation?.groupId in accumulator)) {
          accumulator[gradeEvaluation?.groupId] = {
            name: gradeEvaluation?.group?.name,
            percentage: gradeEvaluation?.group?.percentage,
            stack: 0,
            length: 1,
          };
        } else {
          accumulator[gradeEvaluation?.groupId].length += 1;
        }
    }
    return accumulator;
  }, [partiaGradesEvaluation]);

  const printDoubleHeader = (columns: ColumnTable<StudentProps>[]) => {
    const stackGradeGroup = getStackGradesGroupEvaluation();
    return (
      <>
        <tr key={`header-0-general`} className="table-borderless">
          {columns.map((column) => {
            if (
              !isColumnSubEvaluation(column.columnName) &&
              !isColumnParcialSubEvaluation(column.columnName)
            ) {
              return printHeaderGeneral(column);
            }
            return printHeaderSubEvaluation(column.columnName, stackGradeGroup);
          })}
        </tr>

        <tr
          key={`header-1-subevaluations-and-parcial`}
          className="table-borderless"
        >
          {columns
            .filter(
              (column) =>
                isColumnSubEvaluation(column.columnName) ||
                isColumnParcialSubEvaluation(column.columnName),
            )
            .map((column) => (
              <th key={column.columnName} className="text-center">
                {column.headerText}
              </th>
            ))}
        </tr>
      </>
    );
  };

  return (
    <div className="m-3 py-4 px-3 bg-white rounded">
      <AddEvaluationGradeEntryLoader sectionId={id}>
        {(sectionOfGradeEntry) => (
          <>
            <HeaderSectionOfGradeEntry {...sectionOfGradeEntry} />
            <h2 className="text-primary fs-20 fw-700 mt-2">
              {t('gradeEntry.addEvaluation.title')}
            </h2>
            <p className="fs-16 mt-3 mb-4">
              {t('gradeEntry.addEvaluation.subtitle')}
            </p>
            <Row className="mt-5">
              <Col md={5}>
                <SearchBox
                  name="search"
                  placeholder={t(
                    'gradeEntry.addEvaluation.placeholderSearchBox',
                  )}
                  value={search.value}
                  onChange={search.onChangeSearch}
                  clear={search.onClearSearch}
                />
              </Col>
            </Row>
            {sectionIsClosed && (
              <Row className="mt-1">
                <Col md={12}>
                  <Alert
                    className="mb-0 text-info"
                    color="info"
                    icon="information"
                    size="lg"
                    text={t('gradeEntry.alert')}
                  />
                </Col>
              </Row>
            )}

            <Row className="mt-4">
              <Col />
              <Col>
                <p className="fs-20 mt-3 mb-4 text-primary text-center">
                  {sectionOfGradeEntry.Period_name}
                </p>
              </Col>
              <Col className="d-flex align-items-center">
                <p className="mb-0">
                  {t('gradeEntry.addEvaluation.info.firstAcronym')}{' '}
                  <strong
                    className="pl-2 pr-2"
                    style={{ border: '1px solid var(--primary)' }}
                  >
                    {t('gradeEntry.addEvaluation.grades.notRenderedEvaluation')}
                  </strong>{' '}
                  {t('gradeEntry.addEvaluation.info.secondAcronym')}{' '}
                  <strong
                    className="pl-2 pr-2"
                    style={{ border: '1px solid var(--primary)' }}
                  >
                    {t('gradeEntry.addEvaluation.grades.justifiedEvaluation')}
                  </strong>{' '}
                  {t('gradeEntry.addEvaluation.info.thirdAcronym')}
                </p>
              </Col>
            </Row>
            {loading ? (
              <Loading insideCard />
            ) : (
              <Table
                noDefaultHead={true}
                headComponent={({ columns }) => printDoubleHeader(columns)}
                height={700}
                columns={columns}
                data={search.studentFilteredBySearch(students)}
                striped={false}
                borderless={false}
                isLoadingResults={tableLoading}
                noResultsText={
                  <AddEvaluationGradeEntryNotResult
                    search={search}
                    error={error}
                  />
                }
              />
            )}
          </>
        )}
      </AddEvaluationGradeEntryLoader>
    </div>
  );
}
