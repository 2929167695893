import {
  CellFormatOptions,
  ColumnTable,
  DateInput,
  Icon,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import Tooltip from '../../../../../components/tooltip/Tooltip';
import { formatDate } from '../../../../../utils/dates';
import { useTexts } from '../../../hooks/useTextsContext';
import { isBeforeLimitDate, isSubevaluation } from '../utils/validEvaluation';
import { showDialogInfo } from './../../../../../components/Modals/Dialog';
import { EvaluationType, PartialEvaluation } from './../../../types';
import './../parts/styles/column.scss';
import useStructureTypeIsFixed from './useStructureTypeIsFixed';

export interface PartialEvaluationGradeColumnsProps {
  onChange: (state: PartialEvaluation[]) => void;
  error?: boolean;
  partialEvaluations: PartialEvaluation[];
  onNameChange: (row: { name?: string }) => (e: any) => void;
  onDateChange: (row: { date?: string | Date | null }) => (e: any) => void;
  onWeightingChange: (row: { percentage?: string }) => (e: any) => void;
  onHasLimitDateChange: (row: { hasLimitDate?: boolean }) => (e: any) => void;
  onLimitDateChange: (row: {
    limitDate?: string | Date | null;
  }) => (e: any) => void;
}

export default function usePartialEvaluationGradeColumns({
  onChange,
  error,
  partialEvaluations,
  onNameChange,
  onDateChange,
  onWeightingChange,
  onHasLimitDateChange,
  onLimitDateChange,
}: PartialEvaluationGradeColumnsProps) {
  const texts = useTexts();
  const { isFreeType, isSemiFixedType } = useStructureTypeIsFixed();

  const isExisingtNameDuplicate = useCallback(
    (index: number, name: string) => {
      let errorText = partialEvaluations.find((partialEvaluation, key) => {
        if (key !== index) {
          if (!partialEvaluation.name) {
            return false;
          }
          return partialEvaluation.name === name;
        }
        return false;
      });
      if (errorText) {
        return `*${texts.errors.nameDuplicated}`;
      }
      return undefined;
    },
    [partialEvaluations, texts],
  );

  // Crud operations - Evaluations
  const addPartialEvaluationSubevaluation = useCallback(
    (index: number) => {
      return () => {
        const currentEvaluation = partialEvaluations[index];
        if (isSubevaluation(currentEvaluation)) {
          onChange([
            ...partialEvaluations.slice(0, index),
            {
              ...currentEvaluation,
              grades: [...currentEvaluation.grades, { canItBeRemoved: true }],
            },
            ...partialEvaluations.slice(index + 1),
          ]);
        } else if (currentEvaluation.canItBeRemoved) {
          const { id, date, ...data } = currentEvaluation;
          onChange([
            ...partialEvaluations.slice(0, index),
            {
              ...data,
              type: EvaluationType.Subevaluations,
              grades: [{ canItBeRemoved: true }, { canItBeRemoved: true }],
            },
            ...partialEvaluations.slice(index + 1),
          ]);
        } else {
          showDialogInfo({
            icon: 'warning',
            title: texts.modals.dialog.transformTitle,
            subtitle: texts.modals.dialog.subtitle,
          });
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluations, onChange],
  );
  const deletePartialEvaluationByIndex = useCallback(
    (index: number) => {
      return () => {
        if (partialEvaluations[index].canItBeRemoved) {
          const newPartialEvaluations = [...partialEvaluations];
          newPartialEvaluations.splice(index, 1);
          onChange(newPartialEvaluations);
        } else {
          showDialogInfo({
            icon: 'warning',
            title: texts.modals.dialog.title,
            subtitle: texts.modals.dialog.subtitle,
          });
        }
      };
    },
    [texts, partialEvaluations, onChange],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'evaluation',
        headerText: texts.table.evaluationsTable.columns.evaluation,
        width: '45%',
        thClassName: 'text-center',
        tdProps: { noTdWrap: true },
        cellFormat: ({ row, index }: CellFormatOptions<PartialEvaluation>) => {
          let errorText = error && !row.name ? texts.errors.require : undefined;
          let limitOfChar =
            row.name && row.name.length > 30
              ? texts.errors.charLimit
              : undefined;
          return (
            <td
              colSpan={isSubevaluation(row) ? (row?.hasLimitDate ? 1 : 2) : 1}
            >
              <TextInput
                key={index}
                name={`evaluation-${index as number}`}
                label={texts.form.fields.nameEvaluation}
                type="text"
                value={row.name}
                errorText={
                  isExisingtNameDuplicate(
                    index as number,
                    row.name as string,
                  ) ||
                  limitOfChar ||
                  errorText
                }
                onChange={onNameChange(row)}
              />
            </td>
          );
        },
      },
      {
        columnName: 'date',
        headerText: texts.table.evaluationsTable.columns.date,
        width: '35%',
        thClassName: 'text-center',
        tdProps: { noTdWrap: true },
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          if (options.row?.hasLimitDate && isSubevaluation(options.row)) {
            return (
              <td className="align-top">
                <DateInput
                  name={`limitDate-${options.index}`}
                  label={
                    isSubevaluation(options.row)
                      ? texts.form.fields.limitDate2
                      : texts.form.fields.limitDate1
                  }
                  value={options.row.limitDate}
                  onChange={onLimitDateChange(options.row)}
                  shouldUnregister={true}
                  disabled
                />
              </td>
            );
          }
          if (isSubevaluation(options.row)) {
            return null;
          }

          // Validaciones
          const parentEvaluation = options.row;
          let errorText =
            error && !options.row.date ? texts.errors.require : undefined;
          const errorLimitDate =
            error &&
            parentEvaluation?.hasLimitDate &&
            !isBeforeLimitDate(parentEvaluation, options.row)
              ? texts.errors.notValidDate
              : undefined;

          return (
            <td className="position-relative pt-3">
              {options.row?.hasLimitDate && options.row?.limitDate && (
                <p
                  style={{
                    paddingTop: '5px',
                    position: 'absolute',
                    top: '-7px',
                  }}
                  className="text-primary fs-12 fw-600 m-0"
                >
                  {texts.table.evaluationsTable.columns.limitDate(
                    formatDate(options.row.limitDate, 'DD/MM/YYYY'),
                  )}
                </p>
              )}
              <DateInput
                name={`date-${options.index as number}`}
                label={texts.form.fields.surrencerDate}
                value={options.row.date}
                onChange={onDateChange(options.row)}
                shouldUnregister={true}
                errorText={errorText ?? errorLimitDate}
              />
            </td>
          );
        },
      },
      {
        columnName: 'percentage',
        headerText: texts.table.evaluationsTable.columns.percentage,
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          // Validaciones
          let errorText =
            error && !options.row.percentage ? texts.errors.require : undefined;
          let value =
            options.row.percentage === '0' ? '' : options.row.percentage;

          return (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: '8px' }}
            >
              <div style={{ maxWidth: '100px' }}>
                <TextOutlinedInput
                  type="text"
                  name="percentage"
                  placeholder="—%"
                  value={value}
                  onChange={onWeightingChange(options.row)}
                  errorText={errorText}
                  readOnly={!isFreeType}
                />
              </div>
              {(isFreeType || isSemiFixedType) && (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ gap: '8px' }}
                >
                  <Tooltip
                    id={`info_tooltip_plus_${options.index}`}
                    placement="bottom"
                    text={texts.btn.addSubEvaluation}
                  >
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={addPartialEvaluationSubevaluation(
                        options.index as number,
                      )}
                    >
                      <Icon name="plus" className="mb-3" />
                    </div>
                  </Tooltip>

                  {isFreeType && (
                    <Tooltip
                      id={`info_tooltip_trash_${options.index}`}
                      placement="bottom"
                      text={texts.btn.deleteEvaluation}
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={deletePartialEvaluationByIndex(
                          options.index as number,
                        )}
                      >
                        <Icon name="trash" className="mb-3" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          );
        },
      },
    ];
  }, [
    texts,
    onNameChange,
    onDateChange,
    onWeightingChange,
    isExisingtNameDuplicate,
    deletePartialEvaluationByIndex,
    addPartialEvaluationSubevaluation,
    onLimitDateChange,
    error,
    isFreeType,
    isSemiFixedType,
  ]);

  return columns;
}
