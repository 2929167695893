import { Table } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showDialogInfo } from '../../../../../../components/Modals/Dialog';
import {
  EvaluationType,
  PartialEvaluation,
  PartialEvaluationSubevaluations,
} from '../../../../types';
import { useCreateEvaluationFormContext } from '../../context/CreateEvaluationFormContext';
import sumPercentage from '../../utils/sumPercentage';
import TotalPercentageFooter from '../TotalPercentageFooter';
import { ModalConfirmation } from './../../../../../../components/Modals/Confirmation';
import useSubevaluationGradeColumns from './../../hooks/useSubevaluationColumns';

export interface SubevaluationsTableProps {
  partialEvaluation: PartialEvaluationSubevaluations;
  partialEvaluationIndex: number;
}

const MIN_SUBEVALUATIONS_LENGTH = 2;

export default function SubevaluationsTable(props: SubevaluationsTableProps) {
  const { partialEvaluation, partialEvaluationIndex } = props;
  const { t } = useTranslation();

  const {
    gradeEntry: { testGrades: partialEvaluations },
    onChangePartialEvaluationGradeEntry,
    handleError: { partialEvaluationGradeEntry: error },
  } = useCreateEvaluationFormContext();

  // Callbacks
  const onChange = useCallback(() => {
    onChangePartialEvaluationGradeEntry([...partialEvaluations]);
  }, [onChangePartialEvaluationGradeEntry, partialEvaluations]);

  // Crud operations - Evaluations
  const deletePartialEvaluationByIndex = useCallback(
    (row: { canItBeRemoved: boolean }, data?: PartialEvaluation[]) => {
      return () => {
        if (!data) {
          return;
        }
        if (row.canItBeRemoved) {
          if (
            data?.length === MIN_SUBEVALUATIONS_LENGTH &&
            !data.every((g) => g.canItBeRemoved === true)
          ) {
            showDialogInfo({
              icon: 'warning',
              title: t(
                'gradeEntry.partialEvaluationGradeEntry.dialogInfo.title',
              ),
              subtitle: t(
                'gradeEntry.partialEvaluationGradeEntry.dialogInfo.subtitle',
              ),
            });
            return;
          }

          if (data?.length === MIN_SUBEVALUATIONS_LENGTH) {
            setModalConfirm(true);
          } else {
            const index = data.findIndex((g) => g === row);
            if (index >= 0) {
              data.splice(index, 1);
              partialEvaluation.grades = data;
              onChange();
            }
          }
        } else {
          showDialogInfo({
            icon: 'warning',
            title: t('gradeEntry.partialEvaluationGradeEntry.dialogInfo.title'),
            subtitle: t(
              'gradeEntry.partialEvaluationGradeEntry.dialogInfo.subtitle',
            ),
          });
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, partialEvaluation, onChange],
  );
  const changeToPartialEvaluation = useCallback(() => {
    const { grades, id, ...data } = partialEvaluation;
    partialEvaluations[partialEvaluationIndex] = {
      ...data,
      type: EvaluationType.Normal,
    };
    onChange();
  }, [partialEvaluation, partialEvaluations, partialEvaluationIndex, onChange]);

  // Modal
  const [modalConfirm, setModalConfirm] = useState(false);
  const onConfirm = () => {
    changeToPartialEvaluation();
    setModalConfirm(false);
  };
  const onClose = () => {
    setModalConfirm(false);
  };

  // Hooks
  const columnsSubEvaluations = useSubevaluationGradeColumns({
    onChange,
    error,
    partialEvaluation,
    partialEvaluationIndex,
    deletePartialEvaluationByIndex: (row, data) => {
      return deletePartialEvaluationByIndex(row, data);
    },
  });

  const percentage = sumPercentage(partialEvaluation.grades);
  const percentageValid = percentage === 100;

  return (
    <>
      <Table
        columns={columnsSubEvaluations}
        data={partialEvaluation.grades ?? []}
        striped={false}
        notResponsive
        noDefaultFoot
        footComponent={({ columns, data }) => (
          <TotalPercentageFooter
            dataLength={data.length}
            columnsLength={columns.length}
            error={error}
            percentageValid={percentageValid}
            percentage={percentage}
            isSecondaryFooter
          />
        )}
      />
      {error && !percentageValid && (
        <p className="text-danger mt-2">
          {t('gradeEntry.createEvaluation.msgErrorTables.totalErrorOfTable')}
        </p>
      )}
      {error && partialEvaluation.grades.length < MIN_SUBEVALUATIONS_LENGTH && (
        <p className="text-danger mt-2">
          {t(
            'gradeEntry.createEvaluation.msgErrorTables.totalCountOfTableSubevaluations',
          )}
        </p>
      )}
      <ModalConfirmation
        open={modalConfirm}
        title={'¿Deseas convertir a evaluación simple?'}
        subtitle={
          'Si eliminas estas subevaluación, la evaluación pasará a ser una evaluación simple.'
        }
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
}
