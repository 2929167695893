import { TeachaerSectionCourseOfGradeEntry } from '../../../api/requests/gradeEntry';

export enum EvaluationType {
  Normal = 'normal',
  Subevaluations = 'subevaluations',
}

export interface SectionsGradeConfig {
  sectionData: TeachaerSectionCourseOfGradeEntry;
  gradesConfig: GradesConfig;
}

export interface PartialEvaluationSubevaluation {
  id?: number | null;
  name?: string;
  date?: string | Date | null;
  canItBeRemoved?: boolean;
  percentage?: string;
  orderIndex?: number;
}

export type PartialEvaluationNormal = {
  type: EvaluationType.Normal;
  id?: number | null;
  name?: string;
  date?: string | Date | null;
  canItBeRemoved: boolean;
  percentage?: string;
  hasLimitDate?: boolean;
  limitDate?: string | Date | null;
  orderIndex?: number;
};
export type PartialEvaluationSubevaluations = {
  type: EvaluationType.Subevaluations;
  id?: number | null;
  name?: string;
  date?: string | Date | null;
  canItBeRemoved: boolean;
  percentage?: string;
  grades: PartialEvaluationSubevaluation[];
  hasLimitDate?: boolean;
  limitDate?: string | Date | null;
  orderIndex?: number;
};
export type PartialEvaluation =
  | PartialEvaluationNormal
  | PartialEvaluationSubevaluations;
export interface GradesConfig {
  finalGrade: {
    id?: number;
    partialPercentage?: number;
    examPercentage?: number;
  };
  testGrades: PartialEvaluation[];
}

export enum StructureTypeCode {
  FREE = 1,
  FIXED,
  SEMIFIXED,
}
