import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { EndCourseGrade } from '../parts/Table/EndCourseGradeTable';
import { GradesConfig, PartialEvaluation } from './../../../types';
import {
  isNormal,
  isSubevaluation,
  isValidEvaluations,
} from '../utils/validEvaluation';

interface CreateEvaluationFormContextProviderProps {
  gradesConfig: GradesConfig;
  children: ReactNode;
}

interface CreateEvaluationFormContextType {
  gradeEntry: GradesConfig;
  setGradeEntry: React.Dispatch<React.SetStateAction<GradesConfig>>;
  handleError: {
    partialEvaluationGradeEntry: boolean;
    EndCourseGradeEntry?: string | null;
  };
  setHandleError: React.Dispatch<
    React.SetStateAction<{
      partialEvaluationGradeEntry: boolean;
      EndCourseGradeEntry?: string | null;
    }>
  >;
  endCourseGrades: EndCourseGrade[];
  onChangeEndCourseGradeEntry: (state: EndCourseGrade[]) => void;
  onChangePartialEvaluationGradeEntry: (state: PartialEvaluation[]) => void;
  valiteIfFinalTestIsCorrect: () => string | null | undefined;
  valiteIfTestGradeIsCorrect: () => boolean;
  handleValidate: (execute: () => void) => () => void;
}

const CreateEvaluationFormContext = createContext<
  CreateEvaluationFormContextType | undefined
>(undefined);

export const CreateEvaluationFormContextProvider: React.FC<CreateEvaluationFormContextProviderProps> = ({
  gradesConfig,
  children,
}) => {
  const { t } = useTranslation();

  const [gradeEntry, setGradeEntry] = useState<GradesConfig>(gradesConfig);
  const [handleError, setHandleError] = useState<{
    partialEvaluationGradeEntry: boolean;
    EndCourseGradeEntry?: string | null;
  }>({ partialEvaluationGradeEntry: false });

  const endCourseGrades = useMemo<EndCourseGrade[]>(() => {
    return [
      {
        name: t('gradeEntry.endCourseGradeEntry.partialNote'),
        percentage: `${gradeEntry.finalGrade.partialPercentage || 0}`,
      },
      {
        name: t('gradeEntry.endCourseGradeEntry.examNote'),
        percentage: `${gradeEntry.finalGrade.examPercentage || 0}`,
      },
      {
        name: t('gradeEntry.endCourseGradeEntry.finalNote'),
        percentage: '—%',
      },
    ];
  }, [gradeEntry, t]);

  const onChangeEndCourseGradeEntry = useCallback(
    (state: EndCourseGrade[]) => {
      let [testGrade, finalTest] = state;
      gradeEntry.finalGrade.examPercentage = Number(finalTest.percentage);
      gradeEntry.finalGrade.partialPercentage = Number(testGrade.percentage);
      setGradeEntry({ ...gradeEntry });
    },
    [setGradeEntry, gradeEntry],
  );

  const onChangePartialEvaluationGradeEntry = useCallback(
    (state: PartialEvaluation[]) => {
      gradeEntry.testGrades = [...state];
      setGradeEntry({ ...gradeEntry });
    },
    [setGradeEntry, gradeEntry],
  );

  const valiteIfFinalTestIsCorrect = useCallback(() => {
    let { examPercentage, partialPercentage } = gradeEntry.finalGrade;
    if (!partialPercentage) {
      handleError.EndCourseGradeEntry = t(
        'gradeEntry.createEvaluation.msgErrorTables.saveAllTable',
      );
    } else if (!examPercentage) {
      if (isNaN(Number(examPercentage))) {
        handleError.EndCourseGradeEntry = t(
          'gradeEntry.createEvaluation.msgErrorTables.noEqualZero',
        );
      } else {
        handleError.EndCourseGradeEntry = null;
      }
    } else if (examPercentage + partialPercentage !== 100) {
      handleError.EndCourseGradeEntry = t(
        'gradeEntry.createEvaluation.msgErrorTables.totalErrorOfTable',
      );
    } else {
      handleError.EndCourseGradeEntry = null;
    }
    setHandleError({ ...handleError });
    return handleError.EndCourseGradeEntry;
  }, [gradeEntry, handleError, t]);

  const valiteIfTestGradeIsCorrect = useCallback(() => {
    const validEvaluations = isValidEvaluations(gradeEntry.testGrades);
    handleError.partialEvaluationGradeEntry = !validEvaluations;
    setHandleError({ ...handleError });
    return validEvaluations;
  }, [gradeEntry, handleError, setHandleError]);

  const addOrderIndex = useCallback(() => {
    let orderIndex = 0;
    setGradeEntry((prev) => {
      return {
        ...prev,
        testGrades: prev.testGrades.map((testGrade) => {
          if (isNormal(testGrade)) {
            testGrade.orderIndex = orderIndex;
            orderIndex++;
          }
          if (isSubevaluation(testGrade)) {
            testGrade.grades.forEach((subevaluation) => {
              subevaluation.orderIndex = orderIndex;
              orderIndex++;
            });
          }
          return testGrade;
        }),
      };
    });
  }, []);

  const handleValidate = useCallback(
    (execute: () => void) => {
      return () => {
        addOrderIndex();
        if (!valiteIfFinalTestIsCorrect() && valiteIfTestGradeIsCorrect()) {
          return execute();
        }
      };
    },
    [valiteIfTestGradeIsCorrect, valiteIfFinalTestIsCorrect, addOrderIndex],
  );

  return (
    <CreateEvaluationFormContext.Provider
      value={{
        gradeEntry,
        setGradeEntry,
        handleError,
        setHandleError,
        endCourseGrades,
        onChangeEndCourseGradeEntry,
        onChangePartialEvaluationGradeEntry,
        valiteIfFinalTestIsCorrect,
        valiteIfTestGradeIsCorrect,
        handleValidate,
      }}
    >
      {children}
    </CreateEvaluationFormContext.Provider>
  );
};

export const useCreateEvaluationFormContext = (): CreateEvaluationFormContextType => {
  const context = useContext(CreateEvaluationFormContext);
  if (!context) {
    throw new Error(
      'useCreateEvaluationFormContext must be used within a CreateEvaluationFormContextProvider',
    );
  }
  return context;
};
