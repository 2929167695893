import AuthorizedLayout from '../layouts/AuthorizedLayout';
import NotAuthorizedLayout from '../layouts/NotAuthorizedLayout';
import AttendanceTracking from '../views/AttendanceTracking/AttendanceTracking';
import GradeEntryList from '../views/GradeEntry/GradeEntryList';
import AddEvaluationGradeEntry from '../views/GradeEntry/parts/AddEvaluationGrade/AddEvaluationGradeEntry';
import GradeEntryCreateEvaluation from '../views/GradeEntry/GradeEntryCreateEvaluation';
import ChangePassword from '../views/changePassword/ChangePassword';
import ClassAttendance from '../views/classAttendance/ClassAttendance';
import ClassesHistory from '../views/classesHistory/ClassesHistory';
import Login from '../views/login/Login';
import PeriodEnd from '../views/periodEnd/PeriodEnd';
import PeriodEndReview from '../views/periodEndReview/PeriodEndReview';
import Reports from '../views/reports/Reports';
import StartClasses from '../views/startClasses/StartClasses';
import UntrackedAttendance from '../views/untrackedAttendance/UntrackedAttendance';

export enum PathsLayouts {
  auth = '/auth',
  classes = '/classes',
  gradeEntry = '/grade-entry',
  reports = '/reports',
  periodEnd = '/period-end',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
};

export type RoutesByLayout = {
  [key in PathsLayouts]: {
    layoutComponent: () => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
const routes: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: NotAuthorizedLayout,
    defaultPath: '/',
    views: [
      {
        path: '/',
        component: Login,
      },
      {
        path: '/activate-account',
        component: ChangePassword,
      },
      {
        path: '/reset-password',
        component: ChangePassword,
      },
    ],
  },
  [PathsLayouts.classes]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/start',
    views: [
      {
        path: '/start',
        component: StartClasses,
      },
      {
        path: '/:id/attendance',
        component: ClassAttendance,
      },
      {
        path: '/history',
        component: ClassesHistory,
      },
      {
        path: '/untracked-attendance',
        component: UntrackedAttendance,
      },
      {
        path: '/tracking',
        component: AttendanceTracking,
      },
    ],
  },
  [PathsLayouts.gradeEntry]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/list',
    views: [
      {
        path: '/list',
        component: GradeEntryList,
      },
      {
        path: '/:id/create-evaluation',
        component: GradeEntryCreateEvaluation,
      },
      {
        path: '/:id/add-evaluation',
        component: AddEvaluationGradeEntry,
      },
    ],
  },
  [PathsLayouts.reports]: {
    defaultPath: '/',
    layoutComponent: AuthorizedLayout,
    views: [
      {
        path: '/',
        component: Reports,
      },
    ],
  },
  [PathsLayouts.periodEnd]: {
    defaultPath: '/',
    layoutComponent: AuthorizedLayout,
    views: [
      {
        path: '/',
        component: PeriodEnd,
      },
      {
        path: '/:id',
        component: PeriodEndReview,
      },
    ],
  },
};

export default routes;
