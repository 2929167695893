import { useMemo } from 'react';
import { StructureTypeCode } from '../../../types';
import { useCreateEvaluationGradeEntryBase } from './../../../Loaders/CreateEvaluationGradeEntryBaseLoader';

const useStructureTypeIsFixed = () => {
  const { data } = useCreateEvaluationGradeEntryBase();
  const structureType = data?.sectionData?.StructureType_id;

  const isFreeType = useMemo(() => {
    return !structureType ? true : structureType === StructureTypeCode.FREE;
  }, [structureType]);

  const isSemiFixedType = useMemo(() => {
    return !!structureType && structureType === StructureTypeCode.SEMIFIXED;
  }, [structureType]);

  const isFixedType = useMemo(() => {
    return !!structureType && structureType === StructureTypeCode.FIXED;
  }, [structureType]);

  return {
    isFreeType,
    isSemiFixedType,
    isFixedType,
  };
};

export default useStructureTypeIsFixed;
